import {} from "jquery-ujs";
import "../new_candidate_details";
$(function() {
  if ($(".datepicker").length > 0 && !$.datepicker) {
    $.noConflict();
  }
  var content = $("#content");
  var $datepicker = $(".datepicker");
  if ($datepicker.length > 0) {
    $datepicker.datepicker({ dateFormat: "dd/mm/yy" });
  }
  if (content.length) {
    return $(".sidebar-wrapper").css("top", content.offset().top) + 24;
  }
});
